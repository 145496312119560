import React from 'react';
import { motion } from 'framer-motion';

const LoadingPlaceholder = ({ type }) => {
  const placeholders = {
    work: (
      <div className="py-20">
        <div className="h-10 w-1/4 bg-gray-200 rounded mb-8"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="h-64 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    ),
    techStack: (
      <div className="py-20">
        <div className="h-10 w-1/4 bg-gray-200 rounded mb-8"></div>
        <div className="flex flex-wrap gap-4">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div key={i} className="h-10 w-24 bg-gray-200 rounded-full"></div>
          ))}
        </div>
      </div>
    ),
    worldMap: (
      <div className="py-20">
        <div className="h-80 bg-gray-200 rounded"></div>
      </div>
    ),
    portrait: (
      <div className="relative inline-block">
        <div className="rounded-full bg-gray-200 w-80 h-80"></div>
      </div>
    ),
  };

  const loadingAnimation = {
    animate: {
      background: ['#f3f4f6', '#e5e7eb', '#f3f4f6'],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.div
      animate={loadingAnimation.animate}
      className="w-full"
    >
      {placeholders[type]}
    </motion.div>
  );
};

export default LoadingPlaceholder;