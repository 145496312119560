import React, { useCallback, useEffect, lazy, Suspense } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { IoIosMail } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import LoadingPlaceholder from './Components/LoadingPlaceholder';

// Lazy load components
const Work = lazy(() => import("./Components/Work"));
const WorldMap = lazy(() => import("./Components/WorldMap"));
const TechStack = lazy(() => import("./Components/TechStack"));
const Portrait = lazy(() => import("./Components/Portrait"));

const App = () => {
  return (
    <div className="min-h-screen bg-gray-100 font-sans text-gray-800" style={{ scrollBehavior: 'smooth' }}>
      <Header />
      <main className="container mx-auto p-4 max-w-4xl">
        <Hero/>
        <Suspense fallback={<LoadingPlaceholder type="techStack" />}>
          <TechStack/>
        </Suspense>
        <Suspense fallback={<LoadingPlaceholder type="work" />}>
          <Work/>
        </Suspense>
        <About/>
        <div className="relative">
          <Suspense fallback={<LoadingPlaceholder type="worldMap" />}>
            <WorldMap width="100%" height="400px"/>
          </Suspense>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-100">
            <Contact/>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const useSmoothScroll = () => {
    const scrollToSection = useCallback((e) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    const targetId = href.replace('#', '');
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: 'smooth'
    });
  }, []);

  return scrollToSection;
};

const Header = () => {
    const scrollToSection = useSmoothScroll();

    return (
      <header className="flex justify-between items-center p-6 bg-gray-100 sticky top-0 z-10">
        <h1 className="text-2xl font-light">Pan Zhengyang</h1>
        <nav>
          <a href="#about" className="mr-6 hover:underline" onClick={scrollToSection}>About</a>
          <a href="#work" className="mr-6 hover:underline" onClick={scrollToSection}>Work</a>
          <a href="#contact" className="hover:underline" onClick={scrollToSection}>Contact</a>
        </nav>
      </header>
    )
};

const Hero = () => (
  <motion.section
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8 }}
    className="flex flex-col md:flex-row items-center justify-between py-20"
  >
    <div className="md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left">
      <h2 className="text-4xl font-light">Moro, I'm Pan, <br/> <span className="text-5xl font-normal">Full-Stack Developer</span></h2>
      <p className="text-xl mt-4 font-light">based in Finland</p>
    </div>
    <div className="md:w-1/2 flex justify-center mt-6 md:mt-0">
      <Suspense fallback={<LoadingPlaceholder type="portrait" />}>
        <Portrait />
      </Suspense>
    </div>
  </motion.section>
);

const About = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.section
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
      id="about"
      className="py-20"
    >
      <h2 className="text-4xl font-light mb-8">about.</h2>
      <p className="text-lg mb-4 font-light">
        I'm a second year master student of computer science at Tampere University, with 1 year Full-Stack Development experience and amazing projects.
      </p>
      <Timeline />
    </motion.section>
  );
};

const Timeline = () => (
    <div className="space-y-12">
        <h3 className="text-2xl font-light mt-8">Work Experience</h3>
        <WorkExperienceItem
            jobTitle="Full-Stack Web Developer"
            company="Yiji Information Technology Co"
            duration="June 2022 - May 2023 (1 Year)"
            description={[
                "Created a robust platform that effectively managed inventory and environmental monitoring for pharmaceutical companies.",
                "Rapid digitalization of all warehouses and shelves through QR code marking."
            ]}
            experiences={[
                {"Web-app Development": "Utilized SpringCloud framework to design back-end interface, Vue.js for frontend development, controlling views, giving user a clear and intuitive data display."},
                {"Time-series Database": "Integrated time series database. As a result, data from hundreds of sensors is written consistently, tens of thousands of data are loaded in seconds and visualized quickly using chart."},
                {"Charts Development": "Implement flexible chart views using ECharts to cast different types of data into visualization."},
                {"Message Queue": "Used RabbitMQ message queues to buffer data while receiving MQTT protocol sensor data."},
                {"Agile Development": "Collaborate with team to analyse requirements, develop new features and maintain the system."}
            ]}
        />

        <h3 className="text-2xl font-light mt-8">Education</h3>

        <EducationItem
            years="Sep 2023 - May 2025"
            school="Tampere University"
            location="Tampere, Finland"
            description="Master of Computer Science | GPA: 3.56"
        />

        <EducationItem
            years="Sep 2018 - June 2022"
            school="Sanda University"
            location="Shanghai, China"
            description="Bachelor of Computer Science | GPA: 3.47"
        />



    </div>
);

const EducationItem = ({years, school, location, description}) => (
    <div className="">
        <h4 className="text-xl font-normal text-gray-800">{school}</h4>
        <p className="text-md font-light text-gray-600">{years} | {location}</p>
        <p className="text-lg font-light mt-2">{description}</p>
    </div>
);

const WorkExperienceItem = ({jobTitle, company, duration, description, experiences}) => (
    <div className="mb-8">
        <h4 className="text-xl font-normal text-gray-800">{jobTitle}</h4>
        <p className="text-md font-light text-gray-600">{company} | {duration}</p>
        {typeof description !== "string" ? (
            description.map((des, index) => (
                <p key={index} className="text-lg font-medium mt-2">{des}</p>
            ))
        ) : (
            <p className="text-lg font-light mt-2">{description}</p>
        )}
        <ul className="list-disc list-inside text-lg font-light mt-2">
            {experiences.map((exp, index) => {
                const [title, content] = Object.entries(exp)[0];
                return (
                    <li key={index} className="mb-2">
                        <span className="font-bold">{title}</span>: {content}
                    </li>
                );
            })}
        </ul>
    </div>
);

const Contact = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
      <motion.section
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={{
              visible: {opacity: 1, y: 0},
              hidden: {opacity: 0, y: 50}
          }}
          transition={{duration: 0.5}}
          id="contact"
          className="py-20"
      >
          <h2 className="text-4xl font-light mb-8">contact.</h2>
          <div className="text-lg font-light flex mb-4">
              <IoIosMail className="size-7 mr-2"/> <a href="mailto:panbarryal@gmail.com">panbarryal@gmail.com</a>
          </div>
          <div className="text-lg font-light flex">
              <FaLinkedin className="size-7 mr-2"/> <a href="https://www.linkedin.com/in/pan-zhengyang/">Linkedin</a>
          </div>
      </motion.section>
  );
};

const Footer = () => (
    <footer className="py-6 text-center border-t border-gray-200">
        <p className="text-sm text-gray-600 font-light">Designed and developed by Pan</p>
    </footer>
);
export default App;